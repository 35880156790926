<template>
  <div class="swiper-container" :style="'height:' + clientHeight + 'px'">
    <div class="swiper-wrapper">
      <div class="swiper-slide item-one">
        <div class="companyVideoArea">
          <!-- 
            autoplay
            https://knot-website.oss-cn-beijing.aliyuncs.com/video/home.mp4
           -->
          <video
            muted
            autoplay
            v-if="!time"
            class="companyVideo"
            ref="companyVideo"
            src="https://sichuan-2.zos.ctyun.cn/knot-website/video/home.mp4"
          ></video>
          <div class="video-img" v-else>
            <img
              class="img"
              src="../../assets/images/webhome/homenone.png"
              alt=""
            />
            <img
              class="iconfont"
              @click="videoControl"
              src="../../assets/images/webhome/bofang.png"
              alt=""
            />
            <!-- <i @click="videoControl" class="iconfont icon-24gf-playCircle"></i> -->
          </div>
          <div class="icon-video">
            <!-- el-icon-video-pause -->
          </div>
        </div>
      </div>
      <div class="swiper-slide item-two">
        <div class="conter">
          <div class="left">
            <h1>指云端-智慧医疗服务平台</h1>
            <p>让医疗数据更有价值</p>
          </div>
          <img src="../../assets/images/webhome/zhiyunduan.png" alt="" />
        </div>
      </div>
      <div class="swiper-slide item-three">
        <div class="conter">
          <div class="top" style="margin-top: 68px">
            <h1>指云端-智慧医疗</h1>
            <p>MEDICAL WISDOM</p>
          </div>
          <div class="bottom">
            <div @click="goProduct(1)">
              <img src="../../assets/images/webhome/yunyingxiang.png" alt="" />
              <h1>云影像</h1>
              <p>云影像，云超声，云心电</p>
            </div>
            <div @click="goProduct(2)">
              <img src="../../assets/images/webhome/yuncunchu.png" alt="" />
              <h1>影像云存储</h1>
              <p>影像云归档、影像数据异地容 灾备份、PACS应用级备份</p>
            </div>
            <div @click="goProduct(3)">
              <img src="../../assets/images/webhome/yiliaoxietong.png" alt="" />
              <h1>医疗协同</h1>
              <p>远程影像诊断</p>
              <p>远程阅片</p>
              <p>区域医疗影像云</p>
            </div>
          </div>
        </div>
      </div>
      <div class="swiper-slide item-four">
        <div class="conter">
          <img src="../../assets/images/webhome/zhondian.png" alt="" />
          <div>
            <h1>成都市健康医疗大数据平台云影像提供方</h1>
            <p>
              与中电合作，承建成都市市属医疗机构的云影像建设工作，成为全国首个副省级城市云影像建设提供方。
            </p>
          </div>
        </div>
      </div>
      <div class="swiper-slide item-five">
        <div class="conter">
          <div>
            <h1>我们的合作伙伴</h1>
            <p>COOPERATIVE PARTNER</p>
          </div>
          <div>
            <img
              v-for="item in hospitalImgList"
              :key="item.id"
              :src="item.url"
              alt=""
            />
          </div>
        </div>
      </div>
      <div class="swiper-slide">
        <FOOTER :id="'label' + 4" />
      </div>
    </div>
    <ul class="right-btns">
      <li
        v-for="item in [0, 1, 2, 3, 4, 5]"
        :key="item"
        @click="getIndex(item)"
        :class="{ is_active: item === active }"
      >
        <p :class="{ is_active: item === active }"></p>
      </li>
    </ul>
    <i
      @click="turnPages()"
      class="el-icon-d-arrow-right"
      v-if="active !== 5"
    ></i>
    <div class="gotop" @click="goTop">
      <div><i class="el-icon-top"></i></div>
      <p>TOP</p>
    </div>
  </div>
</template>

<script>
import FOOTER from '../../components/webFooter/index.vue'
// Import Swiper Vue.js components
import Swiper from 'swiper'
import 'swiper/dist/css/swiper.min.css'
export default {
  components: {
    FOOTER
  },
  data() {
    return {
      swiper: null,
      active: 0,
      hospitalImgList: [
        { id: 1, url: require('../../assets/images/web/hospital/1.png') },
        { id: 2, url: require('../../assets/images/web/hospital/2.png') },
        { id: 3, url: require('../../assets/images/web/hospital/3.png') },
        { id: 4, url: require('../../assets/images/web/hospital/4.png') },
        { id: 5, url: require('../../assets/images/web/hospital/5.png') },
        { id: 6, url: require('../../assets/images/web/hospital/6.png') },
        { id: 7, url: require('../../assets/images/web/hospital/7.png') },
        { id: 8, url: require('../../assets/images/web/hospital/8.png') },
        { id: 9, url: require('../../assets/images/web/hospital/9.png') },
        { id: 10, url: require('../../assets/images/web/hospital/10.png') },
        { id: 11, url: require('../../assets/images/web/hospital/11.png') },
        { id: 12, url: require('../../assets/images/web/hospital/12.png') }
      ],
      time: false,
      autoplay: true,
      state: false,
      clientHeight: 0
    }
  },
  methods: {
    // 获取当前高度
    getHeight() {
      // 页面高度
      this.clientHeight = document.body.clientHeight - 67
    },
    // 点击小圆点index跳转相应图层
    getIndex(index) {
      this.active = index
      this.swiper.slideTo(index, 1000, true)
    },
    goTop() {
      this.swiper.slideTo(0, 1000, true)
    },
    turnPages() {
      this.getIndex(this.active + 1)
    },
    videoControl() {
      this.time = !this.time
      this.state = false
      setTimeout(() => {
        this.$refs.companyVideo.play()
      }, 1000)
    },
    // 跳转项目与产品
    goProduct(e) {
      if (e === 1) {
        this.$router.push('/web/product/couldDicom')
      } else if (e === 2) {
        this.$router.push('/web/product/dicomCloudStorage')
      } else {
        this.$router.push('/web/product/regionalCloudDicom')
      }
    },
    isVideoPlay() {
      // 监听视频是否播放完毕
      if (!this.state) {
        // console.log('只执行一次')
        // document.getElementById('video').loop = false // 不设置视频循环播放
        this.$refs.companyVideo.addEventListener(
          'ended',
          () => {
            // console.log(this.$refs.companyVideo)
            // console.log('播放结束')
            // 该视频播放是否已结束
            this.time = !this.time
            this.state = true
            // this.$refs.companyVideo.pause()
          }
          //   // false
        )
        // console.log(this.$refs.companyVideo.ended)
        // if(this.$refs.companyVideo){
        //   console.log('kkj');
        // }
      }
    }
  },
  mounted() {
    var that = this
    this.swiper = new Swiper('.swiper-container', {
      // 速度
      speed: 1000,
      mousewheelControl: true,
      // 前进和后退的按钮
      prevButton: '.swiper-button-prev',
      nextButton: '.swiper-button-next',
      pagination: '.swiper-pagination',
      paginationType: 'bullets',
      centerInsufficientSlides: true,
      // 居中显示
      loopFillGroupWithBlank: true,
      // 展示张数
      slidesPerView: 'auto',
      direction: 'vertical',
      onSlideChangeEnd: function (swiper) {
        // console.log(swiper.activeIndex, '当前选中的标签页面的下标')
        that.active = swiper.activeIndex
      }
    })
    this.isVideoPlay()
    // console.log(this.$refs.companyVideo)
  },
  created() {
    this.getHeight()
  },
  watch: {
    state: {
      handler(data) {
        if (!data) {
          // console.log(data, 'data')
          setTimeout(() => {
            this.isVideoPlay()
          }, 2000)
        }
      },
      deep: true
    }
  }
}
</script>

<style lang="less" scoped>
.swiper-container {
  height: 100%;
  // width: 100%;
  background-color: #0b1f3b;
  .swiper-wrapper {
    h1 {
      font-size: 52px;
      font-family: Microsoft YaHei;
      font-weight: bold;
      color: #ffffff;
      line-height: 58px;
    }
    p {
      font-size: 22px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #9a979b;
      padding-top: 44px;
    }
    .item-one {
      // width: 100%;
      // height: 100%;
      transform:translate3d(0,0,0);
      overflow:hidden;
      .companyVideoArea {
        width: 100%;
        height: 100%;
        .companyVideo {
          width: 100%;
          object-fit: cover;
        }
        .video-img {
          position: relative;
             width: 100%;
            height: 100%;
          .img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
          .iconfont {
            position: absolute;
            cursor: pointer;
            top: 40%;
            left: 47%;
            width: 70px;
            height: 70px;
            // font-size: 50px;
            // color: #fff;
            opacity: 0.8;
            // color: #01060c;
          }
        }
      }
    }
    .item-two {
      transform:translate3d(0,0,0);
      overflow:hidden;
      // width: 100%;
      //  height: 100%;
      background: url('../../assets/images/webhome/beijingyi.png') no-repeat;
      background-size: 100% 100%;
      // overflow: hidden;
      .conter {
        display: flex;
        .left {
          position: relative;
          margin-top: 234px;
          /deep/h1 {
            margin: 0 !important;
             font-size: 50px;
            font-family: Adobe Heiti Std;
            color: #ffffff;
            line-height: 58px;
          }
          p {
            font-size: 28px;
            font-family: Microsoft YaHei;
            letter-spacing: 4px;
            font-weight: 400;
            color: #9a979b;
            padding-top: 44px;
          }
        }
        img {
          margin-top: 77px;
          width: 604px;
          height: 518px;
        }
      }
    }
    .item-three {
      transform:translate3d(0,0,0);
      overflow:hidden;
      background: url('../../assets/images/webhome/beijinger.png') no-repeat;
      background-size: 100% 100%;
      .conter {
        height: 100%;
        text-align: center;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        .top {
          padding-top: 20px;
          h1 {
            font-size: 50px;
            font-family: Adobe Heiti Std;
            color: #ffffff;
            line-height: 58px;
            margin: 0;
            // -webkit-transition: all 6s;
          }
          p {
            font-size: 22px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #41495b;
            padding-top: 0px;
          }
        }
        .bottom {
          box-sizing: border-box;
          display: flex;
          align-items: center;
          justify-content: center;
          justify-content: space-around;
          img {
            width: 138px;
            height: 140px;
          }
          div {
            font-size: 24px;
            width: 277px;
            height: 405px;
            font-family: Microsoft YaHei;
            font-weight: bold;
            cursor: pointer;
            color: #ffffff;
            h1 {
              font-size: 24px;
              font-family: Microsoft YaHei;
              font-weight: bold;
              padding: 0;
              margin: 0;
              padding-top: 10px;
            }
            p {
              font-size: 19px;
              font-family: Microsoft YaHei;
              font-weight: 400;
              color: #9a979b;
              line-height: 40px;
              padding: 0;
              margin: 0;
            }
          }
          div:hover {
            background: url('../../assets/images/webhome/xuanzhong.png')
              no-repeat;
            background-size: 100%;
            img {
              width: 141px;
              height: 143px;
            }
          }
        }
      }
    }
    .item-four {
      transform:translate3d(0,0,0);
      overflow:hidden;
      background: url('../../assets/images/webhome/beijingsan.png') no-repeat;
      background-size: 100% 110%;
      .conter {
        height: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        // justify-content: center;
        img {
          width: 492px;
          height: 445px;
        }
        div {
          width: 594px;
          h1 {
            font-size: 50px;
            font-family: Adobe Heiti Std;
            font-weight: normal;
            color: #ffffff;
            letter-spacing: 4px;
            line-height: 70px;
          }
          p {
            line-height: 40px;
          }
        }
      }
    }
    .item-five {
      transform:translate3d(0,0,0);
      overflow:hidden;
      // width: 100%;
      // height: 91%;
      background: url('../../assets/images/webhome/beijingsi.png') no-repeat;
      background-size: 100% 100%;
      box-sizing: border-box;
      .conter {
        height: 100%;
        text-align: center;
        display: flex;
        flex-direction: column;
        // justify-content: space-around;
        // align-items: center;
        // justify-content: center;
        h1 {
          margin: 0;
          font-size: 52px;
          font-family: Source Han Sans CN;
          font-weight: 500;
          color: #ffffff;
          line-height: 37px;
          padding-top: 4%;
        }
        p {
          padding-top: 1%;
          color: #41495b;
        }
        div:nth-child(2) {
          display: flex;
          justify-content: space-around;
          flex-wrap: wrap;
          margin-top: 0.1%;
          box-sizing: border-box;
          img {
            width: 24%;
            // height: 22%;
            margin-top: 2%;
            object-fit: cover;
            // height: 95px;
            // margin-right: 85px;
          }
          img:nth-child(2n-1) {
            // margin-right: 2%;
          }
          img:nth-child(1) {
            // margin-right: 2%;
          }
        }
      }
    }

    //  &.is_active; &.hover {
    //   background-color: #07ff4e;
    // }
    // li: {
    //   background-color: #07ff4e;
    // }
  }
  .right-btns {
    z-index: 10000;
    position: fixed;
    right: 74px;
    top: 16%;
    li {
      width: 25px;
      height: 25px;
      line-height: 22px;
      box-sizing: border-box;
      text-align: center;
      margin-top: 30px;
      border-radius: 50%;
      &.is_active,
      &:hover {
        border: 1px solid #2185ff;
        p {
          box-sizing: border-box;
          background: #2185ff;
          border-radius: 50%;
        }
      }
      p {
        margin: 0;
        padding: 5px;
        display: inline-block;
        background: #ffffff;
        border-radius: 50%;
        cursor: pointer;
      }
    }
  }
  .el-icon-d-arrow-right {
    z-index: 1000;
    font-size: 50px;
    transform: rotate(90deg);
    color: #c3cdd6;
    position: fixed;
    bottom: 32px;
    right: 49%;
    cursor: pointer;
  }
  .gotop {
    z-index: 10000;
    position: fixed;
    right: 60px;
    bottom: 0;
    cursor: pointer;
    div {
      background-color: #20adff;
      width: 50px;
      height: 50px;
      opacity: 0.93;
      border-radius: 50%;
      text-align: center;
      line-height: 50px;
      font-size: 30px;
      color: #194668;
    }
    p {
      text-align: center;
      font-size: 21px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #20adff;
    }
  }
}
.el-scrollbar__thumb {
  display: none;
}
.el-scrollbar__wrap {
  overflow-x: hidden;
  overflow-y: auto;
}
</style>
